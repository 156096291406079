import jQuery from "jquery";
import select2 from "select2";
export default function selectEnhancer() {
    setTimeout( function() {
        jQuery(function($) {
            $('select').each(function () {
                const placeholder = $(this).data('placeholder');
                
                $(this).select2({
                    dropdownAutoWidth: true,
                    minimumResultsForSearch: Infinity,
                    placeholder: {
                        id: '-1',
                        text: placeholder
                    }
                });
            });
        });
    }, 10);
}
