export default function initTabs() {
  class Tabs {
    constructor(elem) {
      this.elem = elem;
      this.allPanels = elem.querySelectorAll('[role=tabpanel]');
    }

    init() {
      const context = this.elem;
      const panels = this.allPanels;

      panels.forEach((panel) => {
        panel.setAttribute('aria-hidden', true);
      });

      const tabs = context.querySelectorAll('[role=tab]');
      tabs.forEach((tab) => {
        tab.addEventListener('click', (event) => {
          this.onClick(event);
        });
      });

      // Open the tab based on URL hash on page load
      this.openTab(window.location.hash);
    }

    onClick(event) {
      this.event = event;
      history.replaceState({}, '', event.target.getAttribute('href'));
      event.preventDefault();
      if (event.target.getAttribute('role') !== 'tab') return;

      this.openTab(event.target.getAttribute('href'));
    }

    openTab(target) {
      const context = this.elem;
      let tab = target;

      if (!target) {
        tab = context
          .querySelectorAll('[role=tab]')
          .item(0)
          .getAttribute('href');
      }

      // Check if the tab starts with "#tab-"
      if (!tab.startsWith('#tab-')) {
        // Default to opening the first tab if the hash doesn't match any tab IDs
        tab = context.querySelector('[role=tab]').getAttribute('href');
      }

      this.reset();
      context.querySelector(tab).setAttribute('aria-hidden', false);
      context
        .querySelector(`[href="${tab}"]`)
        .setAttribute('aria-selected', true);
    }

    reset() {
      const context = this.elem;
      const panels = this.allPanels;

      panels.forEach((panel) => {
        panel.setAttribute('aria-hidden', true);
      });

      context.querySelectorAll('[role=tab]').forEach((tab) => {
        tab.setAttribute('aria-selected', false);
      });
    }
  }

  const allTabContainers = document.querySelectorAll('[data-tabs]');

  allTabContainers.forEach((el) => {
    const tab = new Tabs(el);
    tab.init();
  });
}
