export default function openMenu() {
  // JavaScript function to handle the menu toggle functionality with event delegation
  function setupMenuToggle() {
    // Event listener for click events on the body
    document.body.addEventListener('click', function (event) {
      // Check if the clicked element is a button with data-toggle="menu"
      if (event.target.matches('button[data-toggle="menu"]')) {
        event.preventDefault(); // Prevent default action if necessary

        const button = event.target;
        const buttonId = button.id;

        // Find the corresponding div with aria-labelledby matching the button's id
        const targetDiv = document.querySelector(
          `div[aria-labelledby="${buttonId}"]`,
        );

        // Close all open menus except the one associated with the clicked button
        closeAllMenus(buttonId);

        if (targetDiv) {
          // Toggle the "is-open" class on the target div
          const isOpen = targetDiv.classList.contains('is-open');
          targetDiv.classList.toggle('is-open', !isOpen);

          // Toggle the "aria-expanded" attribute on the button
          button.setAttribute('aria-expanded', !isOpen);

          // Toggle the "fixed-nav-open" class on the body element
          if (!isOpen) {
            document.body.classList.add('fixed-nav-open');
          } else {
            document.body.classList.remove('fixed-nav-open');
          }
        }
      }
    });

    // Event listener for keydown events on the body for button activation
    document.body.addEventListener('keydown', function (event) {
      // Check if the focused element is a button with data-toggle="menu"
      if (event.target.matches('button[data-toggle="menu"]')) {
        // Check for Enter or Space key
        if (
          event.key === 'Enter' ||
          event.key === ' ' ||
          event.keyCode === 13 ||
          event.keyCode === 32
        ) {
          event.preventDefault(); // Prevent default action (e.g., page scrolling for Space key)

          const button = event.target;
          const buttonId = button.id;

          // Find the corresponding div with aria-labelledby matching the button's id
          const targetDiv = document.querySelector(
            `div[aria-labelledby="${buttonId}"]`,
          );

          // Close all open menus except the one associated with the focused button
          closeAllMenus(buttonId);

          if (targetDiv) {
            // Toggle the "is-open" class on the target div
            const isOpen = targetDiv.classList.contains('is-open');
            targetDiv.classList.toggle('is-open', !isOpen);

            // Toggle the "aria-expanded" attribute on the button
            button.setAttribute('aria-expanded', !isOpen);

            // Toggle the "fixed-nav-open" class on the body element
            if (!isOpen) {
              document.body.classList.add('fixed-nav-open');
            } else {
              document.body.classList.remove('fixed-nav-open');
            }
          }
        }
      }
    });

    // Event listener for keydown events on the document to close the menu with Escape key
    document.addEventListener('keydown', function (event) {
      // Check if the Escape key is pressed
      if (event.key === 'Escape' || event.keyCode === 27) {
        // Close all menus
        closeAllMenus();
      }
    });

    // Function to close all open menus except the one with the specified buttonId
    function closeAllMenus(exceptButtonId) {
      // Find all open menus and close them
      const openMenus = document.querySelectorAll('div.is-open');
      openMenus.forEach(function (menu) {
        // If exceptButtonId is provided, skip closing the associated menu
        if (
          !exceptButtonId ||
          menu.getAttribute('aria-labelledby') !== exceptButtonId
        ) {
          menu.classList.remove('is-open');
        }
      });

      // Reset the "aria-expanded" attribute on all buttons except the one clicked
      const buttons = document.querySelectorAll(
        'button[data-toggle="menu"][aria-expanded="true"]',
      );
      buttons.forEach(function (button) {
        if (!exceptButtonId || button.id !== exceptButtonId) {
          button.setAttribute('aria-expanded', 'false');
        }
      });

      // Remove the "fixed-nav-open" class from the body if no menus are open
      const remainingOpenMenus = document.querySelectorAll('div.is-open');
      if (remainingOpenMenus.length === 0) {
        document.body.classList.remove('fixed-nav-open');
      }
    }
  }

  // Call the function to set up the event listeners
  setupMenuToggle();
}
