export default function initDropdown() {
  const dropdownTriggers = document.querySelectorAll(`[data-toggle=dropdown]`);

  dropdownTriggers.forEach((trigger) => {
    let dropdown = document.querySelector(
      `[aria-labelledby=${trigger.getAttribute('id')}]`,
    );
    let close = dropdown.querySelector('.digila-dropdown-close');
    let viewWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth,
    );
    let dropdownMove = 0;
    let dropdownPosition =
      dropdown.classList.contains('digila-dropdown-bottom-right') ||
      dropdown.classList.contains('digila-dropdown-top-right')
        ? 'right'
        : 'left';

    function documentClickListener(e) {
      if (!dropdown.contains(e.target)) {
        closeDropdown();
      }
    }

    function updateViewWidth() {
      viewWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth,
      );
    }

    function updateDropdownMove() {
      dropdown.style.marginLeft = null;
      let rect = dropdown.getBoundingClientRect();
      let move = 0;
      if (rect.x + rect.width > viewWidth) {
        if (window.matchMedia('(max-width: 899px)').matches) {
          move = rect.x + rect.width - viewWidth + 15;
        } else {
          move = rect.x + rect.width - viewWidth + 10 + 15;
        }

        dropdown.style.marginLeft = -1 * move + 'px';
      }
      dropdownMove = move;
    }

    function updatePosition() {
      updateViewWidth();
      updateDropdownMove();
    }

    function openDropdown() {
      dropdown.classList.add('digila-dropdown-open');
      trigger.classList.add('digila-dropdown-open');
      trigger.setAttribute('aria-expanded', 'true');

      updatePosition();

      setTimeout(function () {
        window.addEventListener('click', documentClickListener, false);
      }, 1);

      // Scroll to dropdown on mobile
      if (window.matchMedia('(max-width: 899px)').matches) {
        // const yOffset = -20
        const yOffset = -70;
        const y =
          trigger.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }

      window.addEventListener('resize', updatePosition, false);
    }

    function closeDropdown() {
      dropdown.classList.remove('digila-dropdown-open');
      trigger.classList.remove('digila-dropdown-open');
      dropdown.style.marginLeft = null;
      trigger.setAttribute('aria-expanded', 'false');

      window.removeEventListener('click', documentClickListener, false);
      window.removeEventListener('resize', updatePosition, false);
    }

    trigger.addEventListener(
      'click',
      (e) => {
        e.preventDefault();

        if (dropdown.classList.contains('digila-dropdown-open')) {
          closeDropdown();
        } else {
          openDropdown();
        }
      },
      false,
    );

    if (close) {
      close.addEventListener(
        'click',
        (e) => {
          e.preventDefault();
          closeDropdown();
        },
        false,
      );
    }
  });
}
