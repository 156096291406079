import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

function initPeopleSlider() {
  const swiper = new Swiper('.people-slider', {
    slidesPerView: 1.1,
    spaceBetween: 32,
    navigation: {
      nextEl: '.people-slider-next',
      prevEl: '.people-slider-prev',
    },
    pagination: {
      el: '.people-slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      700: {
        slidesPerView: 2.1,
      },
      1200: {
        slidesPerView: 3.1,
      },
    },
  });
}

export { initPeopleSlider };

function initStoriesSlider() {
  const isPresent = document.querySelector('.stories-slider');
  if (!isPresent) {
    return;
  }

  let swiperInstance = null;
  const slider = document.querySelector('.stories-slider');
  const sliderList = slider.querySelector('ul');
  const sliderItems = sliderList.querySelectorAll('li');

  function addSwiperClasses() {
    sliderList.classList.add('swiper-wrapper');
    sliderItems.forEach((item) => {
      item.classList.add('swiper-slide');
    });
  }

  function removeSwiperClasses() {
    sliderList.classList.remove('swiper-wrapper');
    sliderItems.forEach((item) => {
      item.classList.remove('swiper-slide');
    });
  }

  function initSlider() {
    addSwiperClasses();
    swiperInstance = new Swiper('.stories-slider', {
      slidesPerView: 1,
      spaceBetween: 24,
      autoHeight: true,
      autoplay: false,
      navigation: {
        nextEl: '.stories-slider-next',
        prevEl: '.stories-slider-prev',
      },
      pagination: {
        el: '.stories-slider-pagination',
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        650: {
          slidesPerView: 2,
        },
      },
    });
  }

  function destroyStoriesSlider() {
    if (swiperInstance) {
      swiperInstance.destroy(true, true); // Destroys the instance and removes events
      swiperInstance = null;
      removeSwiperClasses();
    }
  }

  function checkStoriesSlider() {
    const screenWidth = window.innerWidth;

    if (screenWidth < 900) {
      if (!swiperInstance) {
        initSlider();
      }
    } else {
      if (swiperInstance) {
        destroyStoriesSlider();
      }
    }
  }

  // Initial check on page load
  checkStoriesSlider();

  // Listen for window resize event to recheck screen width
  window.addEventListener('resize', checkStoriesSlider);
}

export { initStoriesSlider };

function initVideoSlider() {
  const sliders = document.querySelectorAll('.video-slider');

  sliders.forEach(function (slider) {
    const parent = slider.closest('.video-slider');

    const nextButton = parent.querySelector('.video-slider-next');
    const prevButton = parent.querySelector('.video-slider-prev');
    const paginationEl = parent.querySelector('.video-slider-pagination');

    const swiper = new Swiper(slider, {
      slidesPerView: 1.1,
      spaceBetween: 8,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
      pagination: {
        el: paginationEl,
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        700: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        1100: {
          slidesPerView: 3,
        },
      },
    });
  });
}

export { initVideoSlider };
