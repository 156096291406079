import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function initPlatformOverview() {
  const platformOverviewSections = gsap.utils.toArray('.stackable-content');

  if (platformOverviewSections.length === 0) {
    return;
  }

  function setupAnimations() {
    // Clear existing ScrollTriggers before setting up new ones
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());

    platformOverviewSections.forEach((section) => {
      const pileDivs = gsap.utils.toArray(
        section.querySelectorAll('.stackable-content-pile .pile-element'),
      );
      const listDivs = gsap.utils.toArray(
        section.querySelectorAll('.stackable-content-list .list-element'),
      );

      const listContainer = section.querySelector('.stackable-content-list');
      const lastPileDiv = pileDivs[pileDivs.length - 1];
      const lastPileHeight = lastPileDiv.offsetHeight;
      listContainer.style.minHeight = `${lastPileHeight}px`;

      // Pinning ScrollTrigger
      ScrollTrigger.create({
        trigger: section,
        start: 'top 40 top',
        end: `+=${pileDivs.length * 100}%`,
        pin: true,
        pinSpacing: true,
        scrub: true,
      });

      // Scroll-triggered animation timeline
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top 50%',
          end: `+=${pileDivs.length * 100}%`,
          scrub: true,
        },
      });

      pileDivs.forEach((pileDiv, index) => {
        const listDiv = listDivs[index];

        tl.fromTo(
          pileDiv,
          { yPercent: 100, opacity: 0, position: 'absolute', top: 0 },
          { yPercent: 0, opacity: 1, duration: 1.5, ease: 'power2.out' },
        );

        if (listDiv) {
          tl.fromTo(
            listDiv,
            { yPercent: 100, opacity: 0 },
            { yPercent: 0, opacity: 1, duration: 1.5, ease: 'power2.out' },
            '<',
          );
        }

        if (index < pileDivs.length - 1) {
          tl.to(
            pileDiv,
            { opacity: 0, duration: 1.5, ease: 'power2.inOut' },
            '+=0.5',
          );
        }
      });
    });

    ScrollTrigger.refresh(); // Refresh ScrollTrigger after setup
  }

  setupAnimations(); // Initial setup

  // Add a resize event listener to recalculate dimensions and refresh ScrollTrigger
  window.addEventListener('resize', () => {
    setupAnimations(); // Re-setup animations on resize
  });
}

export function initStoriesItems() {
  const isPresent = document.querySelector('.stories-list li');
  if (!isPresent) {
    return;
  }

  // Select all the li elements inside the ul.stories-list
  const listItems = document.querySelectorAll('.stories-list li');

  // Set initial opacity to 0 for all list items
  gsap.set(listItems, { opacity: 0 });

  // Create a timeline for the animation
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.stories-list',
      start: 'top 80%', // Animation starts when the top of the list reaches 80% of the viewport height
      // markers: true, // Uncomment to see markers for debugging
    },
  });

  // Define the animation for the list items
  tl.to(listItems, {
    opacity: 1,
    duration: 1,
    stagger: 0.2, // Each item fades in 0.2 seconds after the previous one
    ease: 'power2.out',
  });
}
