export default function toggleVideo() {
  const isPresent = document.querySelector('.play-pause-btn');
  if (!isPresent) {
    return;
  }

  // const video = document.querySelector('.main-hero-video');
  // const videoWrapper = document.querySelector('.front-video-wrap');
  // const btn = document.querySelector('.play-pause-btn');

  // btn.addEventListener('click', () => {
  //   if (video.paused) {
  //     video.play();
  //     btn.classList.add('playing');
  //     videoWrapper.classList.add('playing');
  //   } else {
  //     video.pause();
  //     btn.classList.remove('playing');
  //     videoWrapper.classList.remove('playing');
  //   }
  // });

  const videoWrappers = document.querySelectorAll('.video-wrap');

  videoWrappers.forEach((wrapper) => {
    const video = wrapper.querySelector('.video-wrap-video');
    const btns = wrapper.querySelectorAll('.play-pause-btn');

    btns.forEach((btn) => {
      btn.addEventListener('click', () => {
        if (video.paused) {
          video.play();

          btns.forEach((b) => {
            b.classList.add('playing');
          });

          wrapper.classList.add('playing');
        } else {
          video.pause();

          btns.forEach((b) => {
            b.classList.remove('playing');
          });

          wrapper.classList.remove('playing');
        }
      });
    });
  });
}
